'use client'

import './Header.scss'
import { clsx } from 'clsx'
import { usePathname } from 'next/navigation'
import React, { useEffect, useState } from 'react'
import { NavLink } from '@/components/NavLink'
import { useHover } from '@/hooks/useHover'

export function Header(): React.ReactElement {
  const [servicesRef, servicesHover, setServicesHover] = useHover()
  const [drawerOpen, setDrawerOpen] = useState(false)

  // This is to close the Drawer only when navigation has completed
  // Otherwise, it creates an unwanted flicker
  const pathname = usePathname()
  // biome-ignore lint/correctness/useExhaustiveDependencies: ok
  useEffect(() => {
    if (drawerOpen) {
      hideDrawer()
    }
  }, [pathname])

  function hideHover(): void {
    setServicesHover(false)
  }

  function hideDrawer(): void {
    setDrawerOpen(false)
    setOverflow(true)
  }

  function mobileNav(href: string): void {
    if (href === pathname) {
      // This is needed, because "nagivation" doesn't happen
      hideDrawer()
    }
  }

  function toggleDrawer(): void {
    setOverflow(drawerOpen)
    setDrawerOpen(!drawerOpen)
  }

  function setOverflow(enabled: boolean): void {
    document.body.style.overflow = enabled ? '' : 'hidden'
  }

  return (
    <>
      <header className="header mobile-hidden">
        <div className="header__item header__item--services" ref={servicesRef}>
          <NavLink href="/services/post-production" className="header__link" onClick={hideHover}>
            services
          </NavLink>

          <div
            className="header__dropdown"
            style={{ visibility: servicesHover ? 'visible' : 'hidden' }}
          >
            <ul className="header__menu-list">
              <NavLink
                href="/services/post-production"
                className="header__dropdown-link"
                onClick={hideHover}
              >
                post-production
              </NavLink>
              <NavLink
                href="/services/production"
                className="header__dropdown-link"
                onClick={hideHover}
              >
                production
              </NavLink>
              <NavLink
                href="/services/studio-rental"
                className="header__dropdown-link"
                onClick={hideHover}
              >
                studio rental
              </NavLink>
            </ul>
          </div>
        </div>
        <NavLink href="/portfolio/editorial" className="header__link">
          portfolio
        </NavLink>
        <NavLink href="/" className="header__link header__link_logo">
          <img className="header__logo" src="/images/mud_black_logo.svg" alt="Mud Studios logo" />
        </NavLink>
        <NavLink href="/contact" className="header__link">
          contact
        </NavLink>
        <NavLink href="/mud-lab" className="header__link header__link_mudlab">
          mud-lab
        </NavLink>
        {/* <div className="header__coming-soon">coming soon</div>*/}
      </header>

      <header
        className="header desktop-hidden"
        style={{ backgroundColor: drawerOpen ? '#f0f2f3' : 'rgb(255 255 255 / 86%)' }}
      >
        <NavLink
          href="/"
          onClick={() => mobileNav('/')}
          className="header__link header__link_logo"
          prefetch={true}
        >
          <img className="header__logo" src="/images/mud_black_logo.svg" alt="Mud Studios logo" />
        </NavLink>
        <button className="drawer__btn" onClick={toggleDrawer}>
          <img
            src="/images/cross.svg"
            className={clsx({
              drawer__cross: true,
              drawer__cross_close: drawerOpen,
            })}
            alt="close"
            loading="lazy"
          />
        </button>
      </header>

      <div className={`desktop-hidden drawer ${drawerOpen ? 'drawer_open' : ''}`}>
        <div className="drawer__menu">
          <div className="drawer__submenu">
            <h4 className="drawer__submenu-title">services</h4>
            <NavLink
              href="/services/post-production"
              onClick={() => mobileNav('/services/post-production')}
              className="header__submenu-link"
              prefetch={true}
            >
              post-production
            </NavLink>
            <NavLink
              href="/services/production"
              onClick={() => mobileNav('/services/production')}
              className="header__submenu-link"
              prefetch={true}
            >
              production
            </NavLink>
            <NavLink
              href="/services/studio-rental"
              onClick={() => mobileNav('/services/studio-rental')}
              className="header__submenu-link"
              prefetch={true}
            >
              studio rental
            </NavLink>
          </div>
          <NavLink
            href="/portfolio/editorial"
            onClick={() => mobileNav('/portfolio/editorial')}
            className="drawer__link"
            prefetch={true}
          >
            portfolio
          </NavLink>
          <NavLink
            href="/contact"
            onClick={() => mobileNav('/contact')}
            className="drawer__link"
            prefetch={true}
          >
            contact
          </NavLink>
          <NavLink
            href="/mud-lab"
            onClick={() => mobileNav('/mud-lab')}
            className="drawer__link"
            prefetch={true}
          >
            mud-lab
          </NavLink>
        </div>
      </div>
    </>
  )
}
