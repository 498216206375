import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react'

export function useHover(): [
  ref: RefObject<any>,
  hovered: boolean,
  setHovered: Dispatch<SetStateAction<boolean>>,
] {
  const ref = useRef<any>(null)
  const [hovered, setHovered] = useState(false)

  useEffect(() => {
    function enter(): void {
      setHovered(true)
    }

    function leave(): void {
      setHovered(false)
    }

    const el = ref.current
    if (el) {
      el.addEventListener('mouseenter', enter)
      el.addEventListener('mouseleave', leave)

      return () => {
        el.removeEventListener('mouseenter', enter)
        el.removeEventListener('mouseleave', leave)
      }
    }
  }, [])

  return [ref, hovered, setHovered]
}
