import { _stringify } from '@naturalcycles/js-lib'
import * as sentry from '@sentry/browser'
import { isProd } from '@/env'

export function errorDialog(err: any): void {
  console.error(err)
  sentry.captureException(err)
  alert(_stringify(err))
}

export function initSentry(): void {
  sentry.init({
    dsn: isProd
      ? 'https://6aface4aba50f5bac904981bbfd9ccb6@o129675.ingest.us.sentry.io/4508193590345728'
      : undefined,
    environment: isProd ? 'prod' : 'dev',
    maxValueLength: 2000, // default is 250
    maxBreadcrumbs: 50, // to prevent "413 Request Entity Too Large"
  })
}

export { sentry }
