import { clsx } from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React from 'react'

interface NavLinkProps {
  children?: React.ReactNode
  href: string
  className?: string
  scroll?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  prefetch?: boolean
}

export function NavLink(props: NavLinkProps): React.ReactNode {
  const { children, href, scroll = true, className, onClick, prefetch } = props
  const pathname = usePathname()

  return (
    <Link
      href={href}
      scroll={scroll}
      onClick={onClick}
      prefetch={prefetch}
      className={clsx(className, {
        active: pathname === href,
      })}
    >
      {children}
    </Link>
  )
}
