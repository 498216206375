'use client'
import { AnyObject, BotDetectionService, loadGTag, loadHotjar } from '@naturalcycles/js-lib'
import { isProd } from './env'

const botDetectionService = new BotDetectionService()

/* eslint-disable unicorn/prefer-global-this */

const gtagId = 'G-X7Q6VHE7JT'

export function pageView(props?: AnyObject): void {
  // console.log(props)
  if (!isProd) return
  window.gtag?.('event', 'page_view', {
    ...props,
  })
}

export function setupAnalytics(): void {
  // Only do analytics in prod
  if (!isProd) return

  const enabled = !botDetectionService.isBotOrCDP()

  void loadGTag(gtagId, enabled)

  // Don't load analytics for bots
  if (!enabled) return

  // Page views
  // https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag#manual_pageviews

  window.gtag('config', gtagId, {
    send_page_view: false,
  })

  loadHotjar(5155678)

  loadApollo()
}

function loadApollo(): void {
  /* eslint-disable */
  const n = Math.random().toString(36).substring(7)
  const o = document.createElement('script')
  o.src = 'https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=' + n
  o.async = true
  o.defer = true
  o.onload = () => {
    window.trackingFunctions.onLoad({ appId: '66e04d6d7bef1603ef1ef4d0' })
  }
  document.head.appendChild(o)
  /* eslint-enable */
}

declare global {
  interface Window {
    trackingFunctions?: any
  }
}

Object.assign(globalThis, {
  botDetectionService, // for debugging
})
