import { useEffect } from 'react'

export function useKeyDown(targetKey: string, cb: (e: KeyboardEvent) => any): void {
  useEffect(() => {
    function downHandler(event: KeyboardEvent): void {
      if (event.key === targetKey) {
        cb(event)
      }
    }

    globalThis.addEventListener('keydown', downHandler)
    return () => {
      globalThis.removeEventListener('keydown', downHandler)
    }
  }, [targetKey, cb])
}
