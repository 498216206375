import { AnyFunction } from '@naturalcycles/js-lib'
import { RefObject, useEffect } from 'react'

export function useClickOutside(ref: RefObject<any>, cb: AnyFunction): void {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb()
      }
    }

    globalThis.addEventListener('mousedown', handleClickOutside)
    return () => {
      globalThis.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, cb])
}
