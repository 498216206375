'use client'

import './Footer.scss'
import { _stringify, getFetcher } from '@naturalcycles/js-lib'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React, { useState } from 'react'

export function Footer(): React.ReactElement | null {
  const pathname = usePathname()
  const contactPage = pathname === '/contact'
  const selectedCasesPage = pathname.startsWith('/selected-cases/')
  const mudLabPage = pathname === '/mud-lab'

  const [subscribed, setSubscribed] = useState(false)

  if (contactPage || selectedCasesPage || mudLabPage) {
    return null
  }

  async function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const email = (e.target as HTMLFormElement)['email'].value
    try {
      await getFetcher().postText('https://subscribe-6j3acobjca-ew.a.run.app', { json: { email } })
      // alert('Subscribed!')
      setSubscribed(true)
    } catch (err) {
      alert('Something went wrong\n\n' + _stringify(err))
    }
  }

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__navlinks">
          <Link href="/about-us" className="footer__navlink">
            about us
          </Link>
          {/* <NavLink to="#" className="footer__navlink">*/}
          {/*  careers*/}
          {/* </NavLink>*/}
        </div>
        <form className="footer__subscribe-form" onSubmit={submitForm}>
          <label className="footer__input-label" htmlFor="email">
            stay updated
          </label>
          {!subscribed && (
            <div className="footer__input-field">
              <input
                className="footer__email-input"
                type="email"
                id="email"
                placeholder="e-mail"
                required
              />
              <button className="footer__submit-btn" type="submit" aria-label="Submit" />
            </div>
          )}
          {subscribed && (
            <div className="footer__input-field_subscribed">
              <div className="footer__email-input">Subscribed!</div>
            </div>
          )}
        </form>

        <div className="footer__list-item footer__list-item_email">
          <h3 className="footer__subtitle">send us an email</h3>
          <a className="footer__contact" href="mailto:hello@mudstudios.com">
            hello@mudstudios.com
          </a>
        </div>

        <div className="footer__list-item footer__list-item_phone">
          <h3 className="footer__subtitle">give us a call</h3>
          <a className="footer__contact" href="tel:+46 (0) 8 61 252 24">
            +46 (0) 8 61 252 24
          </a>
        </div>

        <div className="footer__list-item footer__list-item_address">
          <h3 className="footer__subtitle">visit us</h3>
          <a
            className="footer__contact"
            href="https://maps.app.goo.gl/nunBmrT44gkX8RY18"
            target="_blank"
          >
            Sankt Eriksgatan 63C
            <br />
            112 34 Stockholm, Sweden
          </a>
        </div>

        <div className="footer__sm-links">
          <a
            className="footer__sm-link footer__sm-link_linkedin"
            href="https://www.linkedin.com/company/mud-studios/"
            target="_blank"
          >
            <img
              className="footer__sm-img"
              src="/images/657ace929384e4b0553a490b_iconmonstr-linkedin-3.svg"
              loading="lazy"
              alt="LinkedIn"
            />
          </a>
          <a
            className="footer__sm-link footer__sm-link_instagram"
            href="https://www.instagram.com/mudstudios/"
            target="_blank"
          >
            <img
              className="footer__sm-img"
              src="/images/657ace923957de6cff76056d_iconmonstr-instagram-13.svg"
              loading="lazy"
              alt="Instagram"
            />
          </a>
        </div>
      </div>
      <div className="footer__bottom-container">
        <p className="footer__copyright">&copy; 2014–2024 Mud Studios AB. All rights reserved</p>
        <img
          className="footer__logo"
          src="/images/657acca807bc91802ce944f1_Mud-New-Logo.svg"
          alt="MUD studios logo"
          loading="lazy"
        />
      </div>
    </footer>
  )
}
